import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Table, Button, Alert, Card } from "react-bootstrap";
import { apiServices } from "../apiServices/ApiServices";
import { ToastContainer, toast } from "react-toastify";
import { userServices } from "../apiServices/userServices";
import { StatusSevices } from "../apiServices/StatusServices/Service";
import { PageService } from "../apiServices/PageService/service";
import { htmlDialog } from "./Swal";
import { OrderContext } from "../Context/OrderContext";
import { productServices } from "../apiServices/productServices";
export const ManutencaoPedidos = ({
  permissionType,
  operationType,
  nextPage,
  movimentationType,
  operationCode,
  permissionUpdate,
  page,
  status,
}) => {
  const token = localStorage.getItem("token");
  const { handleUpdateOrderWeight, orderAddFinalWeight, cancelOrder } = useContext(OrderContext)

  const [pedidoAtual, setPedidoAtual] = useState({})

  const [materialColor, setMaterialColor] = useState([]);

  const [selectedPesoAtualizar, setSelectedPesoAtualizar] = useState("")

  const handleFetchMaterialColor = async () => {

    const responseMaterialColor = await productServices.selectListMaterialcolor("", token);
    setMaterialColor(responseMaterialColor.data);
  };

  useEffect(() => {
    handleFetchMaterialColor();
  }, []);

  const inputRef = useRef(null);
  const [selectedPage, setSelectedPage] = useState([])
  const [pedidos, setPedidos] = useState([]);

  useEffect(() => {
    setPedidoAtual(pedidos[0])
  }, [pedidos])

  const [value, setValue] = useState("");
  const [codigoPedidos, setCodigoPedidos] = useState([]);

  const [usuarios, setUsuarios] = useState([]);
  const [lote, setLote] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [selectedOperationCode, setSelectedOperationCode] = useState(null);
  const handleSetOperationCode = (e) => {
    setSelectedOperationCode(e);
  };

  const [selectedMovimentationType, setMovimentationType] = useState(null);
  const handlesetMovimentationType = (e) => {
    setMovimentationType(e);
  };

  const [pesoTotal, setPesoTotal] = useState(0);
  const handleSetPesoTotal = (e) => {
    setPesoTotal(e);
  };

  const [detail, setDetail] = useState(null);
  const handleSetDetail = (e) => {
    setDetail(e);
  };

  const handleModalEntered = () => {
    inputRef.current && inputRef.current.focus();
  };

  const handleUpdate = async () => {
    handleUpdateStatus(codigoPedidos, token, status);
  };

  const handleUpdateStatus = async (orders, token, id_status) => {
    const data = {
      order: orders,
      token: token,
      status: 17,
      mainetance: true,
      detail: detail,
      id_page: selectedPage,
      description: `Manutenção de pedidos`,
      lote: {
        batch: lote.lote,
        order_weight: lote.peso,
      },
    };
    toast.dismiss();
    try {
      if (orders.length > 0) {
        const response = await apiServices.updateStatusOrder(data);
        if (response.data.status === "success") {
          toast.success(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          setTimeout(() => {
            setPedidos([]);
            setLote([]);
            setValue([]);
            setCodigoPedidos([]);
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }
    } catch (error) {
      console.error("Erro ao atualizar o status:", error);
    }
  };

  let timerId = null;

  const handleGetOrder = async (token, order) => {
    let response;

    if (order.length === 10) {
      toast.loading("Processando...");
      response = await apiServices.getOrder(
        token,
        1,
        1,
        order,
        null,
        null,
        true
      );
    }

    if (response.data.orders.length !== 0) {
      const existe = pedidos.find(
        (item) => item.CODE === response.data.orders[0].CODE
      );

      const permite =
        response.data.orders[0].ID_STATUS === status &&
        response.data.orders[0].ORDER_PAGE === page;

      if (!existe) {
        setPedidos((prevPedidos) => [...prevPedidos, response.data.orders[0]]);
        setCodigoPedidos((prevPedidos) => [
          ...prevPedidos,
          response.data.orders[0].ID,
        ]);
        toast.dismiss();
        toast.success("Pedido adicionado com sucesso!");
        setValue("");
        handleModalEntered();
      } else {
        toast.dismiss();
        toast.error("Este pedido já foi adicionado.");
        setValue("");
        handleModalEntered();
      }
    } else {
      toast.dismiss();
      toast.error(
        "Nenhum pedido encontrato com este código. Para mais informações, entre em contato com o nosso setor de suporte!"
      );
      setValue("");
      handleModalEntered();
    }
  };

  const removeOrder = (orderCode) => {
    const index = pedidos.findIndex((pedido) => pedido.CODE === orderCode);

    if (index > -1) {
      const updatedPedidos = [...pedidos];
      updatedPedidos.splice(index, 1);
      const updatedCodigo = [...updatedPedidos.map((pedido) => pedido.CODE)];
      setPedidos(updatedPedidos);
      setCodigoPedidos(updatedCodigo);
      toast.success("Pedido removido com sucesso!");
    }
  };

  const handleGetCode = (e) => {
    const valor = e.target.value;
    setValue(valor);

    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      handleGetOrder(token, valor);
    }, 1000);
  };

  const Metais = pedidos.map((dado) => dado.MATERIAL_CODE);
  const MetalUnico = Array.from(new Set(Metais));
  const Metal = MetalUnico.map((item, index) => {
    if (MetalUnico.length > 1 && index !== MetalUnico.length - 1) {
      return item;
    } else {
      return item;
    }
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current && inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (permissionUpdate === true) {
      handleUpdate();
    }
  }, [permissionUpdate]);

  const [selectedOption, setSelectedOption] = useState("")

  const [pageList, setPageList] = useState([]);

  const handleGetPage = async () => {
    const payload = {
      token: localStorage.getItem('token'),
      status_edit: true
    }
    const response = await PageService.list(payload)
    setPageList(response.data.result)
  }

  useEffect(() => {
    handleGetPage();
  }, [])

  const handleVoltar = () => {
    setPedidos([]);
    setLote([]);
    setValue([]);
    setCodigoPedidos([]);
    setSelectedOption("")
    setSelectedPesoAtualizar("")
  }

  const [pesoFinal, setPesoFinal] = useState([])

  const handleSubmitUpdatePeso = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = {
      key: formData.get('field_order_weight'),
      value: pesoFinal.length > 0 ? pesoFinal : formData.get('order_weight'),
      order_code: formData.get("order"),
      id_orders: [formData.get("id_order")],
      id_page: '20',
    }

    const htmlContent = `
      <div>
        <span><strong>Pedido: </strong>${payload.order_code}</span><br>
        <span><strong>Peso anterior: </strong>${pedidos[0][String(formData.get('field_order_weight'))?.toUpperCase()]}</span>
        <span><strong>Novo peso: </strong>${payload.value} (g)</span><br>
      </div>
    `


    const confirmation = await htmlDialog(
      `Você deseja realmente alterar o peso do pedido ${payload.order_code}?`,
      htmlContent
    );
    if (confirmation.isConfirmed) {
      await orderAddFinalWeight(payload)
    }
  }


  const handleSetPesoFinal = (cor, peso) => {
    const obj = {
      material_color_id: cor,
      weight: peso,
    };

    // Supondo que você tenha um estado ou array que mantém as cores e pesos.
    let updatedArray = [...pesoAdicional]; // Seu array original onde os objetos estão.

    // Encontrar o índice do item com a cor passada
    const index = updatedArray.findIndex(item => item.material_color === cor);

    if (index !== -1) {
      if (peso == 0) {
        // Se o peso for 0, remover o item do array
        updatedArray.splice(index, 1);
      } else {
        // Se o peso for diferente de 0, atualiza o peso e a ordem
        updatedArray[index].weight = peso;
      }
    } else {
      if (peso !== 0) {
        // Caso não exista o item e o peso seja diferente de 0, adicionar o novo objeto
        updatedArray.push(obj);
      }
    }

    // Agora você pode atualizar o estado ou fazer qualquer coisa com o array atualizado
    setPesoFinal(updatedArray); // Supondo que você esteja usando algum setState
  };

  const [pesoAdicional, setPesoAdicional] = useState([])

  const handleSetPesoAdicional = (cor, peso) => {
    const obj = {
      material_color_id: cor,
      weight: peso,
    };

    // Supondo que você tenha um estado ou array que mantém as cores e pesos.
    let updatedArray = [...pesoAdicional]; // Seu array original onde os objetos estão.

    // Encontrar o índice do item com a cor passada
    const index = updatedArray.findIndex(item => item.material_color === cor);

    if (index !== -1) {
      if (peso == 0) {
        // Se o peso for 0, remover o item do array
        updatedArray.splice(index, 1);
      } else {
        // Se o peso for diferente de 0, atualiza o peso e a ordem
        updatedArray[index].weight = peso;
      }
    } else {
      if (peso !== 0) {
        // Caso não exista o item e o peso seja diferente de 0, adicionar o novo objeto
        updatedArray.push(obj);
      }
    }

    // Agora você pode atualizar o estado ou fazer qualquer coisa com o array atualizado
    setPesoAdicional(updatedArray); // Supondo que você esteja usando algum setState
  };

  const handleSubmitPesoAdicional = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = {
      key: 'aditional_weight',
      value: pesoAdicional,
      order_code: formData.get("order"),
      id_orders: [formData.get("id_order")],
      id_page: '20',
    }

    const htmlContent = `
      <div>
        <span><strong>Pedido: </strong>${payload.order_code}</span><br>
        <span><strong>Peso: </strong>${payload.value} (g)</span><br>
      </div>
    `


    const confirmation = await htmlDialog(
      `Você deseja realmente adicionar o peso do pedido ${payload.order_code}?`,
      htmlContent
    );
    if (confirmation.isConfirmed) {
      await orderAddFinalWeight(payload)
    }
  }

  const handleSubmitOrderCancellation = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = {
      cancel_reason: formData.get('cancel_reason'),
      order_code: formData.get('order_code'),
      id_orders: [formData.get("id_order")],
    }

    const htmlContent = `
      <div>
        <span><strong>Pedido: </strong>${payload.order_code}</span><br>
        <span><strong>Cancelamento: </strong>${payload.cancel_reason}</span><br>
      </div>
    `

    const confirmation = await htmlDialog(
      `Você deseja realmente cancelar o pedido ${payload.order_code}?`,
      htmlContent
    );
    if (confirmation.isConfirmed) {
      const response = await cancelOrder(payload)
      if (response.data.status == "success") {
        handleVoltar()
      }
    }
  }


  return (
    <>
      <button
        className="w-100 bg-transparent border border-0"
      >
        <div className="d-flex gap-3 flex-column align-items-center justify-content-center">
          <div className="w-100 h-100 d-flex gap-3 flex-column align-items-center justify-content-center">

            <Card className="h-100 w-100 card-one">
              <Card.Body className="h-100 d-flex justify-content-start">
                {pedidos && pedidos.length >
                  0
                  ? <Button className="d-flex flex-row justify-content-between align-items-center gap-1 m-0" onClick={() => handleVoltar()} >

                    Voltar
                    <i class="ri-arrow-go-back-line"></i>
                  </Button> :
                  <Form.Group className="w-100">
                    <Form.Label className="text-center w-100">
                      Aguarde o leitor ou digite o código do pedido
                    </Form.Label>
                    <Form.Control
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                      type="text"
                      className="display-3 h-20 z-3 w-100 text-center"
                      ref={inputRef}
                      autoFocus={page !== 13}
                      id="code-form"
                      value={value}
                      onChange={handleGetCode}
                    />
                  </Form.Group>
                }
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="mt-3 w-100" style={{ overflowX: "auto" }}>
          <div className="d-flex flex-row gap-3">
            {pedidos.length > 0 ? (
              pedidos.map((order) => (
                <>
                  <Card key={order.ID} className="card-one" style={{ minWidth: "350px", width: "350px" }}>
                    <Card.Img variant="top" src={order.FILE} alt="Imagem do produto" />
                    <Card.Body>
                      <Card.Title className="text-center mb-4">Pedido: {order.CODE}</Card.Title>
                      <p className="d-flex flex-column align-items-start w-100">
                        <span><strong>Cliente:</strong> {order.NAME_CLIENT}<br /></span>
                        <span><strong>Categoria:</strong> {order.CATEGORY}<br /></span>
                        <span><strong>Código da Peça:</strong> <br />{order.PART_CODE}</span>
                        <span><strong>Data:</strong> {order.DATE}<br /></span>
                        <span><strong>Peso final fundição: </strong> {order.FINAL_WEIGHT || 0} (g)<br /></span>
                        <span><strong>Peso final Acabamento: </strong> {order.ACABAMENTO_FINAL_WEIGHT || 0} (g)<br /></span>
                        <span><strong>Peso final Polimento: </strong> {order.POLIMENTO_WEIGHT || 0} (g)<br /></span>
                        <span><strong>Status:</strong> {order.STATUS}</span>
                      </p>
                    </Card.Body>
                  </Card>

                  <div className="d-flex w-100 flex-row flex-wrap gap-3">
                    <div className="d-flex flex-row w-100 gap-3 justify-content-between">
                      <Card className="card-one w-100">
                        <Card.Body className="w-100">
                          <Form.Group className="w-100">
                            <Form.Label><span>Escolha o que você precisa fazer:</span></Form.Label>
                            <Form.Select onChange={(e) => setSelectedOption(e.target.value)} aria-label="Selecione o tipo de manutenção" className="w-100">
                              <option>Selecione a opção desejada</option>
                              <option value={1}>Alterar o etapa do pedido</option>
                              <option value={2}>Atualizar o peso final do pedido (para várias telas)</option>
                              <option value={3}>Cancelar pedido</option>
                              <option value={4}>Peso adicional em pedido</option>
                              {/* <option value={3}>Adicionar notas ou comentários ao pedido</option>
                            <option value={4}>Agendar uma nova entrega</option> */}
                            </Form.Select>
                          </Form.Group>
                        </Card.Body>
                      </Card>

                      <Card className="card-one w-100 h-100">
                        {selectedOption == "1" ? (<Card.Body>
                          <Form.Group className="w-100">
                            <Form.Label><span className="">Escolha a etapa:</span></Form.Label>
                            <Form.Select aria-label="Selecione o tipo de manutenção" onChange={(e) => setSelectedPage(e.target.value)} className="w-100">
                              <option>Selecione o Status que deseja alterar</option>
                              {
                                pageList && pageList.map((p, i) => {
                                  if (p.id < order.ORDER_PAGE) {
                                    return (
                                      <option key={i} name={`page_${p.id}`} value={p.id}>{p.page_description}</option>
                                    )
                                  }
                                })
                              }
                            </Form.Select>
                          </Form.Group>
                        </Card.Body>) : null}
                        {selectedOption == '2' ? (<form onSubmit={handleSubmitUpdatePeso}>
                          <Card.Body>
                            <input type="hidden" name="order" value={order.CODE} />
                            <input type="hidden" name="id_order" value={order.ID} />
                            <Form.Group>
                              <Form.Label>Escolha o campo a ser atualizado</Form.Label>
                              <Form.Select onChange={(e) => {
                                setSelectedPesoAtualizar(e.target.value)
                                setPesoFinal([])
                              }} name="field_order_weight" id="field_order_weight">
                                {[
                                  {
                                    field: "final_weight",
                                    title: "Peso final de Fundição"
                                  },
                                  {
                                    field: 'acabamento_final_weight',
                                    title: "Peso final de Acabamento"
                                  },
                                  {
                                    field: "polimento_weight",
                                    title: "Peso após polimento"
                                  },].map((field, index) => (
                                    <option value={field.field}>{field.title}</option>
                                  ))}
                              </Form.Select>
                            </Form.Group>

                            <Form.Group>
                              <Form.Label>Insira o peso</Form.Label>

                              {order.MATERIAL_COLOR_ID == 6 && selectedPesoAtualizar == "polimento_weight" &&
                                materialColor
                                  ?.filter(item => ![7, 8, 5, 3, 6].includes(item.id))
                                  .map(item => (
                                    <Form.Group>
                                      <Form.Label className="d-flex flex-row justify-content-start w-100-"><strong>{item.name?.replace(" c/ Ródio", "")}</strong></Form.Label>
                                      <Form.Control
                                        type="number"
                                        required
                                        step="0.01"
                                        onChange={e => handleSetPesoFinal(item.id, e.target.value)}
                                        className="w-100"
                                        placeholder="Digite o peso da peça!"
                                      />
                                    </Form.Group>
                                  ))
                              }

                              {order.MATERIAL_COLOR_ID != 6 && selectedPesoAtualizar == "polimento_weight" &&

                                <Form.Group>
                                  <Form.Label className="d-flex flex-row justify-content-start w-100-"><strong>{order.MATERIAL_COLOR?.replace(" c/ Ródio", "")}</strong></Form.Label>
                                  <Form.Control
                                    type="number"
                                    required
                                    step="0.01"
                                    onChange={e => handleSetPesoFinal(order.MATERIAL_COLOR_ID, e.target.value)}
                                    className="w-100"
                                    placeholder="Digite o peso da peça!"
                                  />
                                </Form.Group>

                              }
                              {
                                selectedPesoAtualizar != "polimento_weight" &&
                                <Form.Group>
                                  <Form.Label className="d-flex flex-row justify-content-start w-100-"><strong>{order.MATERIAL_COLOR?.replace(" c/ Ródio", "")}</strong></Form.Label>

                                  <Form.Control
                                    name="order_weight"
                                    id="order_weight"
                                    step={'0.01'} s
                                  ></Form.Control>
                                </Form.Group>
                              }
                            </Form.Group>
                          </Card.Body>
                          <Card.Footer>
                            <Button type="submit">Salvar</Button>
                          </Card.Footer>
                        </form>) : null}
                        {selectedOption == "3" && <Card>
                          <form onSubmit={handleSubmitOrderCancellation}>
                            <Card.Body>
                              <Form.Group>
                                <Form.Label>Motivo do cancelamento</Form.Label>
                                <input type="hidden" name="order_code" value={order.CODE} />
                                <input type="hidden" name="id_order" value={order.ID} />
                                <Form.Control name="cancel_reason" id="cancel_reason" placeholder="Digite aqui o motivo do cancelamento..." as="textarea" rows={18}>

                                </Form.Control>
                              </Form.Group>
                            </Card.Body>
                            <Card.Footer>
                              <Button type="submit">Cancelar Pedido</Button>
                            </Card.Footer>
                          </form>
                        </Card>}


                        {selectedOption == '4' ? (<form onSubmit={handleSubmitPesoAdicional}>
                          <Card.Body>
                            <input type="hidden" name="order" value={order.CODE} />
                            <input type="hidden" name="id_order" value={order.ID} />
                            <Form>
                              <Form.Label>Peso adicional em pedido:</Form.Label>
                              {
                                materialColor
                                  ?.filter(item => ![7, 8, 5, 3, 6].includes(item.id))
                                  .map(item => (
                                    <Form.Group>
                                      <Form.Label className="d-flex flex-row justify-content-start w-100-"><strong>{item.name?.replace(" c/ Ródio", "")}</strong></Form.Label>
                                      <Form.Control
                                        type="number"
                                        required
                                        name="polimento_weight"
                                        id="polimento_weight"
                                        step=".03"
                                        onChange={e => handleSetPesoAdicional(item.id, e.target.value)}
                                        className="w-100"
                                        placeholder="Digite o peso da peça!"
                                      />
                                    </Form.Group>
                                  ))
                              }
                              {/* 
{
                              order.MATERIAL_COLOR_ID == 6 ? (
                                materialColor
                                  ?.filter(item => ![7, 8, 5, 3, 6].includes(item.id))
                                  .map(item => (
                                    <Form.Group>
                                      <Form.Label className="d-flex flex-row justify-content-start w-100-"><strong>{item.name?.replace(" c/ Ródio", "")}</strong></Form.Label>
                                      <Form.Control
                                        type="number"
                                        required
                                        name="polimento_weight"
                                        id="polimento_weight"
                                        step=".03"
                                        onChange={e => handleSetPesoAdicional(item.id, e.target.value)}
                                        className="w-100"
                                        placeholder="Digite o peso da peça!"
                                      />
                                    </Form.Group>
                                  ))
                              ) : (
                              <Form.Group>
                                <Form.Label className="d-flex flex-row justify-content-start w-100"><strong>{materialColor.find(item => item.id == order.MATERIAL_COLOR_ID)?.name?.replace(" c/ Ródio", "")}</strong></Form.Label>
                                <Form.Control
                                  type="number"
                                  required
                                  name="polimento_weight"
                                  id="polimento_weight"
                                  step=".03"
                                  onChange={e => handleSetPesoAdicional(order.MATERIAL_COLOR_ID, e.target.value)}
                                  className="w-100"
                                  placeholder="Digite o peso da peça!"
                                />
                              </Form.Group>
                              )} */}
                            </Form>
                          </Card.Body>
                          <Card.Footer>
                            <Button type="submit">Salvar</Button>
                          </Card.Footer>
                        </form>) : null}
                      </Card>
                    </div>

                    <div className="d-flex flex-column w-100 gap-3 justify-content-between">
                      <Card className=" w-100 card-one">
                        <Card.Body className="h-100">
                          <Form.Label>Detalhamento</Form.Label>
                          <Form.Control
                            onChange={(e) => handleSetDetail(e.target.value)}
                            name="detail"
                            id="detail"
                            as="textarea"
                          ></Form.Control>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <Card className="w-100">
                <Card.Body>
                  <Alert variant="info">Nenhum pedido encontrado ou adicionado</Alert>
                </Card.Body>
              </Card>
            )}


          </div>

          {page !== 13 ? null : (
            <Card className="mt-3 d-flex flex-row gap-3 w-100">
              <div className="h-100 w-100">
                <Card.Body className="h-100">
                  <Form.Label className="text-center w-100">
                    Peso total:
                  </Form.Label>
                  <Form.Control
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                    type="text"
                    onChange={(e) => handleSetPesoTotal(e.target.value)}
                    value={pesoTotal}
                    className="bg-white display-3 h-20 z-3 w-100 text-center"
                    name="peso-total"
                    id="peso-total"
                  />
                </Card.Body>
              </div>
              <Card className="h-100 w-100 card-one">
                <Card.Body className="h-100">
                  <Form.Label>Detalhamento</Form.Label>
                  <Form.Control
                    onChange={(e) => handleSetDetail(e.target.value)}
                    name="detail"
                    id="detail"
                    as="textarea"
                  ></Form.Control>
                </Card.Body>
              </Card>
            </Card>
          )}
        </div>

      </button >

      <ToastContainer />
    </>
  );
};
